import * as React from 'react';

import clsx from 'clsx';
import emailjs from '@emailjs/browser';
import { Link } from 'gatsby';

import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import * as RDD from 'react-device-detect';
import ReCAPTCHA from 'react-google-recaptcha';

import { useIPAddress, useSearchParams } from '../../hooks';
import { usePageProps } from '../../contexts';

const BLOCKED_DOMAINS = [
  'gmail',
  'outlook',
  'hotmail',
  'live',
  'msn',
  'yahoo',
  'icloud',
];

const isBlockedDomain = (email: string) => {
  const domain = email.split('@')?.[1]?.toLowerCase() || '';
  const rootDomain = domain.split('.')?.[0] || '';
  return BLOCKED_DOMAINS.includes(rootDomain);
};

interface ContactFormValues {
  name: string;
  email: string;
  phone: string;
  about: string;
  privacyPolicy: boolean;
}

const contactFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .max(256, "The name can't have more than 256 characters")
    .required('The name is required'),
  email: yup
    .string()
    .email('The email must have a valid format')
    .test(
      'blocked-domain',
      'Only business domains are allowed',
      (value) => !value || !isBlockedDomain(value),
    )
    .matches(
      /.+\.[a-zA-Z]+$/,
      'The email must end with a valid domain suffix (e.g., .com, .eu)',
    )
    .max(256, "The email can't have more than 256 characters")
    .required('The email is required'),
  phone: yup
    .string()
    .max(256, "The phone can't have more than 256 characters")
    .optional(),
  about: yup
    .string()
    .min(50, "The message can't have less than 50 characters")
    .max(256, "The message can't have more than 256 characters")
    .required('The message is required'),
  privacyPolicy: yup.boolean().isTrue().required(),
});

const CONTACT_FORM_DEFAULT_VALUES: ContactFormValues = {
  name: '',
  email: '',
  phone: '',
  about: '',
  privacyPolicy: false,
};

export interface ContactFormProps
  extends React.ComponentPropsWithoutRef<'div'> {
  label?: React.ReactNode;
}

export default function ContactForm({
  label,
  className,
  ...props
}: ContactFormProps) {
  const ip = useIPAddress();
  const page = usePageProps();
  const params = useSearchParams(page?.location ?? null);

  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null);
  const formRef = React.useRef<HTMLFormElement | null>(null);

  const hiddenInputs = React.useMemo(
    () => [
      { name: 'origin', value: 'Your Digital Partner' },
      { name: 'page', value: 'Your Digital Partner' },
      {
        name: 'device',
        value:
          (RDD.isMobile && 'Mobile') ||
          (RDD.isDesktop && 'Desktop') ||
          'Unknown',
      },
      { name: 'user_agent', value: RDD.getUA },
      { name: 'ip', value: ip },
      { name: 'utm_source', value: params.get('utm_source') ?? '' },
      { name: 'utm_medium', value: params.get('utm_medium') ?? '' },
      { name: 'utm_campaign', value: params.get('utm_campaign') ?? '' },
      { name: 'utm_content', value: params.get('utm_content') ?? '' },
      { name: 'utm_term', value: params.get('utm_term') ?? '' },
    ],
    [RDD, ip, params],
  );

  const handleChangeCheckbox: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const input = document.querySelector(
      `label[for="${e.target.id}"].w-checkbox > div.w-checkbox-input`,
    );
    if (!input) return;
    if (e.target.checked) {
      input.classList.add('w--redirected-checked');
    } else {
      input.classList.remove('w--redirected-checked');
    }
  };

  const handleSubmit = async () => {
    if (formRef.current) {
      try {
        await emailjs.sendForm('mosano-ses', 'landing-pages', formRef.current);
        formRef.current.reset();

        // @ts-ignore Fire a custom event after successful form submission
        window.dataLayer.push({
          event: 'formSubmissionSuccess',
          eventCategory: 'form',
          eventAction: 'submit',
          eventLabel: 'Your Digital Partner Landing Page',
          eventValue: 1,
        });
        window.location.assign('https://mosano.eu/thanks?yourdigitalparnter');
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div
      id="contact-form"
      data-w-id="07875213-2ee7-f39f-5578-f1faecdf2509"
      className={clsx('wp-form', className)}
      {...props}
    >
      {label && (
        <h3 className="h2 center" style={{ marginBottom: 24 }}>
          {label}
        </h3>
      )}
      <div
        id="wf-form-content"
        data-name="Contact Form"
        className="form w-form"
        style={{ marginBottom: 0 }}
      >
        <Formik
          initialValues={CONTACT_FORM_DEFAULT_VALUES}
          validationSchema={contactFormValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form
              ref={formRef}
              id="form_landingPage"
              name="wf-form-Contact-Form"
              data-name="Contact Form"
              className="form-text w-clearfix"
              aria-disabled={isSubmitting}
            >
              {hiddenInputs.map((input, index) => (
                <input
                  key={`hidden-input#${index}`}
                  type="hidden"
                  id={input.name}
                  name={input.name}
                  data-name={input.name}
                  value={input.value}
                  style={{ display: 'none' }}
                />
              ))}
              <div className="form-name">
                <label htmlFor="name" id="name-label" className="form-label">
                  Name
                </label>
                <Field
                  type="text"
                  className="form-field w-input"
                  name="name"
                  data-name="name"
                  placeholder=""
                  id="name"
                  required
                  autoComplete="name"
                  readOnly={isSubmitting}
                />
                <ErrorMessage
                  name="name"
                  render={(error) => <FieldError>{error}</FieldError>}
                />
              </div>
              <div className="wp-form-contacts">
                <div className="form-email">
                  <label
                    htmlFor="email"
                    id="email-label"
                    className="form-label"
                  >
                    Email
                  </label>
                  <Field
                    type="email"
                    className="form-field w-input"
                    name="email"
                    data-name="email"
                    placeholder=""
                    id="email"
                    required
                    autoComplete="email"
                    readOnly={isSubmitting}
                  />
                  <ErrorMessage
                    name="email"
                    render={(error) => <FieldError>{error}</FieldError>}
                  />
                </div>
                <div className="form-phone">
                  <label
                    htmlFor="phone"
                    id="phone-label"
                    className="form-label"
                  >
                    Phone (optional)
                  </label>
                  <Field
                    type="tel"
                    className="form-field w-input"
                    name="phone"
                    data-name="phone"
                    placeholder=""
                    id="phone"
                    autoComplete="tel"
                    readOnly={isSubmitting}
                  />
                  <ErrorMessage
                    name="phone"
                    render={(error) => <FieldError>{error}</FieldError>}
                  />
                </div>
              </div>
              <div className="form-about" style={{ marginBottom: 20 }}>
                <label htmlFor="about" id="about-label" className="form-label">
                  Message
                </label>
                <Field
                  as="textarea"
                  className="form-field w-input"
                  name="about"
                  data-name="about"
                  placeholder=""
                  id="about"
                  required
                  readOnly={isSubmitting}
                />
                <ErrorMessage
                  name="about"
                  render={(error) => <FieldError>{error}</FieldError>}
                />
              </div>
              <div className="form-privacy">
                <label
                  htmlFor="Privacy-Policy"
                  className="w-checkbox wp-checkbox privacypolicy"
                >
                  <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox privacypolicy"></div>
                  <Field
                    type="checkbox"
                    id="Privacy-Policy"
                    name="privacyPolicy"
                    data-name="Privacy Policy"
                    readOnly={isSubmitting}
                    required
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeCheckbox(event);
                      setFieldValue('privacyPolicy', event.target.checked);
                    }}
                    style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                  />
                  <span className="caption w-form-label">
                    By submitting this form, I consent to Mosano collecting my
                    information for marketing and commercial purposes in
                    accordance with the{' '}
                    <Link to="/privacy-policy">Privacy Policy</Link>. I
                    understand that I can unsubscribe or withdraw my consent at
                    any time.
                  </span>
                </label>
              </div>
              <div className="submit-button-row">
                {process.env.GATSBY_RECAPTCHA_SITE_KEY && (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  />
                )}
                <button
                  type="submit"
                  data-wait="Please wait..."
                  id="tracking-button"
                  className="button form w-button submit-button"
                  style={{ marginLeft: 'auto' }}
                  disabled={isSubmitting}
                >
                  Send Message
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="success-message w-form-done">
          <div className="div-block-47 w-clearfix">
            <div className="text-block-7">
              Thank you! Your submission has been received!
            </div>
            <p className="paragraph2 center">
              Now visit our website to get to know everything about us.
            </p>
            <a
              href="https://mosano.eu/"
              target="_blank"
              rel="noopener noreferrer"
              className="button website w-button"
            >
              Visit Website
            </a>
          </div>
        </div>
        <div className="error-message w-form-fail">
          <div className="text-block-8">
            Oops! Something went wrong while submitting the form.
          </div>
        </div>
      </div>
    </div>
  );
}

interface FieldErrorProps extends React.ComponentProps<'p'> {}

function FieldError({ style, children, ...props }: FieldErrorProps) {
  return (
    <p style={{ color: '#e5000d', marginBottom: 0, ...style }} {...props}>
      {children}
    </p>
  );
}
