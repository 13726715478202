import * as React from 'react';
import type { PageProps } from 'gatsby';

const PagePropsContext = React.createContext<PageProps | null>(null);

export const usePageProps = () => React.useContext(PagePropsContext);

interface PagePropsProviderProps extends React.PropsWithChildren {
  props: PageProps;
}

export const PagePropsProvider = ({
  props,
  children,
}: PagePropsProviderProps) => (
  <PagePropsContext.Provider value={props}>
    {children}
  </PagePropsContext.Provider>
);
