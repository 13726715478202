import * as React from 'react';

export function useIPAddress() {
  const [ip, setIP] = React.useState('');

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const fetchIP = async () => {
        try {
          const response = await fetch('https://api.ipify.org?format=json');
          const data = await response.json();
          setIP(data.ip);
        } catch (error) {
          console.error('Failed to fetch IP address:', error);
        }
      };

      fetchIP();
    }
  }, []);

  return ip;
}
